import React, { useEffect, useState } from "react";
import "./Stopwatch.css";

const Stopwatch = ({ onStart, onStop }) => {
  const [tenths, setTenths] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [stopwatchId, setStopwatchId] = useState(undefined);

  const handleStartWatch = () => {
    console.log("handleStartWatch");
    onStart();
    setTenths(0);
    setSeconds(0);
    setMinutes(0);
    setStopwatchId(
      setInterval(() => {
        setTenths((prev) => prev + 1);
      }, 10)
    );
  };

  useEffect(() => {
    if (tenths > 99) {
      setSeconds((prev) => prev + 1);
      setTenths(0);
    }
  }, [tenths]);

  useEffect(() => {
    if (seconds > 59) {
      setMinutes((prev) => prev + 1);
      setSeconds(0);
    }
  }, [seconds]);

  const handleStopWatch = () => {
    onStop();
    clearInterval(stopwatchId);
    setStopwatchId(undefined);
  };

  return (
    <div className="stopwatch">
      <div>
        {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}:
        {String(tenths).padStart(2, "0")}
      </div>
      <button
        className="stopwatchbutton"
        type="button"
        onClick={stopwatchId ? handleStopWatch : handleStartWatch}
      >
        {stopwatchId ? "Stop" : "Start"}
      </button>
    </div>
  );
};

export default Stopwatch;
